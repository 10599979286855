<template>
  <div class="container">
    <div class="submit">
      <el-button type="primary" @click="updateNotice" v-if="mode == 'edit'"
        >修改资讯</el-button
      >
      <el-button type="primary" @click="addNotice" v-else>发布资讯</el-button>
    </div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-row>
        <el-col :span="4">
          <el-form-item label="封面图片" required>
            <avatar-uploader @getImgUrl="getImgUrl" :url="form.index_img">
            </avatar-uploader> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="展示平台" required>
            <el-select v-model="form.platform" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
          
        <el-col :span="4">
          <el-form-item label="类型" required>
            <el-select v-model="form.type" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="置顶">
           <el-switch v-model="form.topping" /></el-form-item
        ></el-col>
      </el-row>
      <el-form-item label="资讯标题" required>
        <el-row>
          <el-col :span="24">
            <el-input
              v-model="form.title"
              placeholder="填写资讯的标题"
            ></el-input
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="资讯摘要">
        <el-row>
          <el-col :span="24">
            <el-input
              type="textarea"
              placeholder="填写资讯的摘要或子标题"
              maxlength="50"
              show-word-limit
              v-model="form.digest"
            ></el-input
          ></el-col>
        </el-row>
      </el-form-item>
    
       <el-form-item label="资讯链接">
        <el-row>
          <el-col :span="24">
            <el-input
              v-model="form.url"
              placeholder="填写资讯的标题"
            ></el-input
          ></el-col>
        </el-row>
          <p>点击资讯后跳转的链接，可进入公众文章点击右上角获取链接</p>
      </el-form-item>
      <el-form-item label="资讯内容" >
        <wangEditor
          v-model="wangEditorDetail"
          :isClear="isClear"
          class="wang"
        ></wangEditor>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import wangEditor from "@/components/wangEditor";
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  components: { wangEditor },
  data() {
    return {
      isClear: false,
      wangEditorDetail: "",
      temp: {},
      form: {},
      manager: JSON.parse(localStorage.getItem("managerInfo")),
      mode: "add",
      id: "",
      options: [
        {
          value: "all",
          label: "全部平台",
        },
        {
          value: "coach",
          label: "教练端",
        },
        {
          value: "mechanism",
          label: "机构端",
        },
      ],
      typeList:[
        {
          value: "notice",
          label: "资讯",
        },
        {
          value: "publice",
          label: "公告",
        },
      ],
    };
  },
  mounted() {
    //设置富文本框默认显示内容
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.mode = "edit";
      this.getInfo();
    } else {
      this.wangEditorDetail = "";
      this.mode = "add";
      // this.form.type = 'notice'
    }
  },

  methods: {
    getInfo() {
      this.$axios({
        url: "/user/notice/findById",
        params: { id: this.id },
      }).then((res) => {
        this.wangEditorDetail = res.data.data.content;
        this.temp = Object.assign({}, res.data.data);
        this.form = res.data.data;
      });
    },

    updateNotice() {
      this.form.content = this.wangEditorDetail;
      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      if (this.check()) {
        data.id = this.id;
        this.$axios.post("/user/notice/update", data).then(() => {
          this.$message("修改成功");
          this.$router.back();
        });
      }
    },
    getImgUrl(file) {
      this.form.index_img = file;
    },
    addNotice() {
        this.form.content  = this.wangEditorDetail
      if (this.check()) {
        let data = {
          content: this.wangEditorDetail,
          create_user_id: this.manager.user_id,
          create_user_name: "海马运动",
          digest: this.form.digest,
          index_img: this.form.index_img,
          platform: this.form.platform,
          title: this.form.title,
            url: this.form.url,
          type: this.form.type,
          topping:this.form.topping,
        };
        this.$axios.post("/user/notice/insert", data).then(() => {
          this.$message("添加成功");
          this.$router.back();
        });
      }
    },
    check() {
      if (!this.form.title) {
        this.$message("请填写标题");
        return false;
      }
      if (!this.form.platform) {
        this.$message("请选择展示平台");
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.container {
  position: relative;
}
.submit {
  position: absolute;
  right: 50px;
  top: 30px;
  z-index: 9999;
}
.wang {
  height: 500px;
}
</style>